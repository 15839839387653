//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        padding:'140px 0px',
        background:grey[50]
    },
    container:{
        boxSizing:'border-box',
        width:'1200px',
        margin:'auto',
        padding:'16px',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'100%',
        },
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    subcontainer:{
        margin:'40px 0px'
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px'
    },
    button:{
        background:'#4b6bf5',
        color:'white',
        padding:'12px 28px',
        borderRadius:'24px',
        textTransform:'none',
        fontSize:'1em',
        fontWeight:'600',
        '&:hover':{
            background:'#4b6bf5',
            boxShadow:'0 10px 20px rgba(75, 107, 245, 0.16), 0 6px 6px rgba(75, 107, 245, 0.23)'
        }
    },
    
})

export default styles;