import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Slide, Grow} from '@material-ui/core';
import SimpleIconCard from '../../../../../../components/Home/SimpleIconCard/SimpleIconCard.jsx';
import InViewMonitor from 'react-inview-monitor';

class SectionD extends Component{

      state = {
        title:[false,false],
        services:[false,false,false,false,false,false,false,false,false,false,false],
        anchorEl:null,
        open:false
    }

    shouldComponentUpdate(nextProps, nextState){
      return this.props.content !== nextProps.content ||
              JSON.stringify(this.state) !== JSON.stringify(nextState)
    }


    stepActivation = () => {

    }

    onInview = (event, key, pos) => {
      let temp = [...this.state[key]];
      temp[pos] = true;
      this.setState({[key]:[...temp]})
    }


    render(){

        const {classes, content,sendToPage} = this.props;
        const {title, services} = this.state;

        return(
          <div className={classes.section}>
                <div className={classes.container}>
                    <Grid container alignItems='center' justify='center'>
                        <Grid item xs={12}>
                          <Grid container alignItems='center' direction='column'>
                            <Grid item>
                              <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                                <Slide direction="left" in={title[0]} timeout={1000}>
                                  <Typography variant='h4' align='justify' className={classes.title}>
                                      {content.title}
                                  </Typography>
                                </Slide>
                              </InViewMonitor>
                            </Grid>
                            <Grid item>
                              <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                                <Slide direction="right" in={title[1]} timeout={1000}>
                                  <div className={classes.divider}/>
                                </Slide>
                              </InViewMonitor>
                            </Grid>
                          </Grid>  
                            
                        </Grid>
                        <Grid  item xs={12}>  
                            <div className={classes.subcontainer} >
                              
                                <Grid container spacing={3}>
                                  {content.data.map((item,key)=>{
                                    return(
                                      <Grid item xs={4} key={key.toString()}>
                                        <InViewMonitor onInView={(event) => this.onInview(event, 'services', key)} >
                                          <Grow in={services[key]} timeout={700+500*(key%3)}>
                                            <div>
                                              <SimpleIconCard
                                                data={{
                                                  icon:item.image,
                                                  title:item.title,
                                                  content:item.content,
                                                  button:item.button,
                                                  color:key % 4,
                                                }}
                                                onClick={(event) => sendToPage(event,'/services')}
                                              />
                                            </div>
                                          </Grow>
                                        </InViewMonitor>
                                      </Grid>
                                    )
                                  })}
                                </Grid>
                              
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionD);