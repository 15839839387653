import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid } from '@material-ui/core';
import {Slide, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';


import TechItem from '../../../../../../components/Technologies/TechItem/TechItem.jsx';
import webdev from '../../../../../../assets/Technologies/webdev.png';


class SectionB extends Component{

    state = {
      title:[false,false, false],
      techs:[false,false,false,false,false,false,false,false]
    }

    shouldComponentUpdate(nextProps, nextState){
      return this.props.content !== nextProps.content ||
              JSON.stringify(this.state) !== JSON.stringify(nextState)
    }


    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    }

    render(){

        const {classes, content} = this.props;
        const {title, techs} = this.state;

        return(
            
            <div  className={classes.section}>
                
              <div className={classes.container}>
              
                <Grid container align='center'>
                  <Grid item xs={12}>  
                    <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                      <Slide direction='left' in={title[0]} timeout={1000}>
                        <Typography variant='h4' align='center' className={classes.title}>
                            {content.title}
                        </Typography>
                      </Slide>
                    </InViewMonitor>
                  </Grid>
                  <Grid item xs={12}>
                    <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                      <Slide direction='right' in={title[1]} timeout={1000}>
                          <div className={classes.divider}/>
                      </Slide>
                    </InViewMonitor>
                  </Grid>
                  <Grid item xs={12}>
                  <InViewMonitor onInView={(event) => this.onInview(event, 'title', 2)} >
                    <Grow in={title[2]} timeout={1000}>
                      <div className={classes.content_container}>
                        <Typography align='center' className={classes.content}>
                          {content.content}
                        </Typography>
                      </div>
                    </Grow>
                  </InViewMonitor>
                  </Grid>
                  <Grid item xs={12}>
                    <InViewMonitor onInView={(event) => this.onInview(event, 'techs', 0)} >
                        <div className={classes.subcontainer}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Grow in={techs[0]} timeout={1000}>
                                  <img src={webdev} alt={''} className={classes.image}/>
                              </Grow>
                            </Grid>
                            {
                              content.data.map((item,key) => {
                                return(
                                  <Grid item xs={6} key={key.toString()}>
                                    <Grow in={techs[0]} timeout={1000} >
                                      <div className={classes.program}>
                                          <TechItem data={item} right ismobile/>
                                      </div>
                                    </Grow>
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                        </div>
                    </InViewMonitor>
                  </Grid>
                </Grid>
              </div>
            </div>
            
        )
    }
}

export default withStyles(styles)(SectionB);