import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';
import KeyFeature from '../../../../../../components/About/KeyFeature/KeyFeature.jsx';


class SectionB extends Component{

    state = {
        topics:[false,false,false],
        values:[false],
        vals:[false,false,false,false]
    }

    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    }

    render(){

        const {classes, content} = this.props;
        const {topics, values, vals} = this.state;
        return(
            <div className={classes.section}>
                <div className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography align='center' className={classes.titleA}>
                                {content.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.subcontainer}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <InViewMonitor onInView={(event) => this.onInview(event, 'topics', 0)} >
                                            <Grow in={topics[0]} timeout={1000}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.company_title}>
                                                            {content.company.title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography align='justify' className={classes.company_content}>
                                                            {content.company.content}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grow>
                                        </InViewMonitor>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InViewMonitor onInView={(event) => this.onInview(event, 'topics', 1)} >
                                            <Grow in={topics[1]} timeout={1000}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.mission_title}>
                                                            {content.mission.title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography align='justify' className={classes.mission_content}>
                                                            {content.mission.content}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grow>
                                        </InViewMonitor>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InViewMonitor onInView={(event) => this.onInview(event, 'topics', 2)} >
                                            <Grow in={topics[2]} timeout={1000}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.mission_title}>
                                                            {content.vision.title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography align='justify' className={classes.mission_content}>
                                                            {content.vision.content}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grow>
                                        </InViewMonitor>
                                    </Grid>

                                </Grid>
                            </div>   
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <InViewMonitor onInView={(event) => this.onInview(event, 'values', 0)} >
                                            <Grow in={values[0]} timeout={1000}>
                                                <Typography align='center' className={classes.mission_title}>
                                                    {content.values.title}
                                                </Typography>
                                            </Grow>
                                        </InViewMonitor>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container  alignItems='center' spacing={3}>
                                            {content.values.data.map((item,key)=>{
                                                return(
                                                    <Grid item xs={6} key={key.toString()}>
                                                        <InViewMonitor onInView={(event) => this.onInview(event, 'vals', key)} >
                                                            <Grow in={vals[key]} timeout={1000}>
                                                                <div>
                                                                    <KeyFeature
                                                                        data={item}
                                                                        color={key%4}
                                                                    />
                                                                </div>
                                                                
                                                            </Grow>
                                                        </InViewMonitor>
                                                        
                                                    </Grid>
                                                    
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionB);