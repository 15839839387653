import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import Slider from "react-slick";
import{Typography, Grid, Slide, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';


class SectionE extends Component{

    state = {
        title:[false,false, false],
    }

    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    }



    render(){

        const {classes, content} = this.props;
        const {title} = this.state;

        const settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "8px",
            slidesToShow: 7,
            slidesToScroll: 1,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 3500,
            cssEase: "linear"
            
        };
        

        return(
            <div className={classes.section}>
                <div className={classes.container}>
                    <Grid container align='center'>
                        <Grid item xs={12}>  
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                                <Slide direction='left' in={title[0]} timeout={1000}>
                                    <Typography variant='h4' align='center' className={classes.title}>
                                        {content.title}
                                    </Typography>
                                </Slide>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                                <Slide direction='right' in={title[1]} timeout={1000}>
                                    <div className={classes.divider}/>
                                </Slide>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 2)} >
                                <Grow in={title[2]} timeout={1000}>
                                    <div className={classes.content_container}>
                                        <Typography align='center' className={classes.content}>
                                            {content.content}
                                        </Typography>
                                    </div>
                                </Grow>
                            </InViewMonitor>
                        </Grid>
                    </Grid>
                    
                    <div className={classes.slider_container}>
                        <Slider {...settings}>
                            <div>
                                <img src={content.clients[0].image} alt={''} className={classes.image}/>
                            </div>
                            <div>
                                <img src={content.clients[1].image} alt={''} className={classes.image}/>
                            </div>
                            <div>
                                <img src={content.clients[2].image} alt={''} className={classes.image}/>
                            </div>
                            <div>
                                <img src={content.clients[3].image} alt={''} className={classes.image}/>
                            </div>
                            <div>
                                <img src={content.clients[4].image} alt={''} className={classes.image}/>
                            </div>
                            <div>
                                <img src={content.clients[5].image} alt={''} className={classes.image}/>
                            </div>
                            <div>
                                <img src={content.clients[6].image} alt={''} className={classes.image}/>
                            </div>
                            <div>
                                <img src={content.clients[7].image} alt={''} className={classes.image}/>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionE);