//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        margin:'100px 0px',
    },
    container:{
        boxSizing:'border-box',
        width:'1200px',
        margin:'auto',
        padding:'16px',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'100%',
        },
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px',
    },
    divider:{
        marginTop:32,
        width:50,
        minHeight:5,
        background:grey[300],
        marginBottom:40
    },
    carouselSection:{
        position:'relative'
    },
    greyBG:{
        backgroundColor:grey[50],
        width: '1000%',
        height: '100%',
        position: 'absolute',
        zIndex: -1,
        transform: 'translate(-50%, -10px)'
    }
})

export default styles;