import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Slide, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';


class SectionE extends Component{

    state = {
        title:[false,false, false],
    }

    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    }



    render(){

        const {classes, content} = this.props;
        const {title} = this.state;        

        return(
            <div className={classes.section}>
                <div className={classes.container}>
                    <Grid container align='center'>
                        <Grid item xs={12}>  
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                                <Slide direction='left' in={title[0]} timeout={1000}>
                                    <Typography variant='h4' align='center' className={classes.title}>
                                        {content.title}
                                    </Typography>
                                </Slide>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                                <Slide direction='right' in={title[1]} timeout={1000}>
                                    <div className={classes.divider}/>
                                </Slide>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 2)} >
                                <Grow in={title[2]} timeout={1000}>
                                    <div className={classes.content_container}>
                                        <Typography align='center' className={classes.content}>
                                            {content.content}
                                        </Typography>
                                    </div>
                                </Grow>
                            </InViewMonitor>
                        </Grid>
                    </Grid>
                    
                    <div className={classes.slider_container}>
                        <Grid container spacing={3}>
                            {
                                content.clients.map((item,key)=>{
                                    return(
                                        <Grid item xs={6} key={key.toString()}>
                                            <div>
                                                <img src={item.image} alt={''} className={classes.image}/>
                                            </div>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionE);