//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';
//import background from '../../../../../../assets/Home/background1.jpg';

const styles = (theme) => ({
    section:{
        padding:'100px 0px',
        background:grey[50]
    },
    container:{
        boxSizing:'border-box',
        width:'1200px',
        margin:'auto',
        padding:'16px',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'100%',
        },
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300],
        margin:'auto'
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px',
        margin:'auto'
    },
    content_container:{
        maxWidth:'550px',
        margin:'auto'
    },
    content:{
        color:grey[300],
        fontWeight:'700',
        fontSize:'6em',
        margin:'auto'
    },
    paper:{
        borderRadius:0,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)'
    },
    form:{
        padding:'64px 56px',
        borderRight:`1px solid ${grey[200]}`
    },
    button:{
        background:'#4b6bf5',
        color:'white',
        padding:'12px 28px',
        borderRadius:'24px',
        textTransform:'none',
        fontSize:'1em',
        fontWeight:'600',
        '&:hover':{
          background:'#4b6bf5',
          boxShadow:'0 10px 20px rgba(75, 107, 245, 0.16), 0 6px 6px rgba(75, 107, 245, 0.23)'
        }
    },
    contactTitle:{
        textTransform:'uppercase',
        fontWeight:'600',
        color:grey[900],
        fontSize:'0.9em'
    },
    contactContent:{
        textTransform:'none',
        fontWeight:'500',
        color:grey[700],
        fontSize:'0.9em'
    }
})

export default styles;