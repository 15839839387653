import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import{Typography, Grid, Button, Grow } from '@material-ui/core';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
  section:{

  },
  container:{

  },
  background:{
    position:'relative',
    width:'100%',
    height: '680px',
    backgroundSize: "cover",
    backgroundPosition: "center center",
    [theme.breakpoints.only('md')]: {
      height: '550px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '300px',
    },
  },
  dataContainer:{
    margin:'auto',
    marginLeft:'108px',
    width:'480px',
    [theme.breakpoints.only('md')]: {
      boxSizing:'border-box',
      width:'100%',
      padding:'40px 40px',
      margin:0
    },
    [theme.breakpoints.down('sm')]: {
      width:'100%',
      padding:'40px 16px',
      margin:0
    },
  },
  dataContainerLeft:{
    margin:'auto',
    marginLeft:'108px',
    width:'480px',
    [theme.breakpoints.only('md')]: {
      boxSizing:'border-box',
      width:'100%',
      padding:'40px 40px',
      margin:0
    },
  },
  title:{
      fontWeight:'600',
      fontSize:'2.2em'
  },
  subtitle:{
    fontWeight:'600',
    color:grey[500],
    textTransform:'uppercase'
  },
  content:{
    color:grey[700],
    width:'340px',
  },
  button:{
    background:'#4b6bf5',
    color:'white',
    padding:'12px 28px',
    borderRadius:'24px',
    textTransform:'none',
    fontSize:'1em',
    fontWeight:'600',
    '&:hover':{
      background:'#4b6bf5',
      boxShadow:'0 10px 20px rgba(75, 107, 245, 0.16), 0 6px 6px rgba(75, 107, 245, 0.23)'
    }
  },
  
  
})

class SolutionWrapper extends Component{

    render(){

        const {classes, image, title, content, position, ismobile, subtitle, button, visited, url, onClick} = this.props;

        let contentLeft = null;
        let contentRight = null;

        if(position === 'left' || ismobile){
          contentLeft = (
            <div className={classes.background} style={{backgroundImage:`url(${image})`}}/>
          );
          contentRight = (
            <Grow in={visited} timeout={1500}>
              <div className={classes.dataContainer}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant='body2' className={classes.subtitle}>
                      {subtitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h3' className={classes.title}>
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' className={classes.content}>
                      {content}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}/>
                  <Grid item xs={12}>
                    <Button variant='contained' className={classes.button} onClick={(event) => onClick(event,url)}>
                      {button}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grow>
            
          );
        }else{
          contentRight = (
            <div className={classes.background} style={{backgroundImage:`url(${image})`}}/>
          );
          contentLeft = (
            <Grow in={visited} timeout={1500}>
              <div className={classes.dataContainerLeft}>
                <Grid container direction='column' spacing={4}>
                  <Grid item>
                    <Typography variant='body2' className={classes.subtitle}>
                      {subtitle}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='h3' className={classes.title}>
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body1' className={classes.content}>
                      {content}
                    </Typography>
                  </Grid>
                  <Grid item/>
                  <Grid item>
                    <Button variant='contained' className={classes.button} onClick={(event) => onClick(event,url)}>
                      {button}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grow>
            
          );
        }

        let ncolumns = ismobile ? 12:6; 


        return(
          <div className={classes.container}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container alignItems='center'>
                  <Grid item xs={12} md={ncolumns}>
                    {contentLeft}
                  </Grid>
                  <Grid item xs={12} md={ncolumns}>
                    {contentRight}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )
    }
}

export default withStyles(styles)(SolutionWrapper);