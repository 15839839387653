//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        padding:'100px 0px',
        background:'rgba(0,0,0,0.025)'
    },
    container:{
        boxSizing:'border-box',
        width:'100%',
        margin:'auto',
        padding:'8px 16px',
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px',
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    data_container:{
        margin:'32px 100px'
    },
    content_container:{
        maxWidth:'550px',
        margin:'40px',
        color:grey[500],
    },
    content:{
        color:grey[700],
        fontWeight:'400',
        fontSize:'1.1em'
    },
    sliderContainer:{
        width:'500px',
        overflow:'hidden'
    },
    image:{
        width:'100%',
    },
    slider_container:{
        padding:'30px 0px'
    }
    
})

export default styles;