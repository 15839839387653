import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Hidden
} from '@material-ui/core';
import ReactGA from 'react-ga';
import {contentData} from './content.jsx';

import ContactDesktop from './ContactDesktop/ContactDesktop.jsx';
import ContactMobile from './ContactMobile/ContactMobile.jsx';

class Contact extends Component{

  componentDidMount(){
    window.scrollTo(0,0)
    ReactGA.initialize('UA-134909612-1');
    ReactGA.pageview(window.location.pathname);
  }


  render(){

    const {language} = this.props
    const content = contentData[language]
    
    
    let desktopView = (
      <Hidden smDown>
        <ContactDesktop content={content}/>
      </Hidden>
    )

    let mobileView = (
      <Hidden mdUp>
        <ContactMobile content={content}/>
      </Hidden>
    )

    return(
      <div>
      {desktopView}
      {mobileView}
    </div>
    )
  }
}

export default withStyles(styles)(Contact);
