import {blueGrey} from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    position: 'relative',
    '& $notchedOutline': {
      borderColor:blueGrey[100],
      borderRadius:'40px',
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: blueGrey[200],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        borderColor:'blue',
      },
    },
    '&$focused $notchedOutline': {
      borderColor: '#4b6bf5',
      borderWidth: 1,
    },
    '&$error $notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '&$disabled $notchedOutline': {
      borderColor: theme.palette.action.disabled,
    },
  },
  /* Styles applied to the root element if the component is focused. */
  focused: {},
  /* Styles applied to the root element if `disabled={true}`. */
  disabled: {},
  /* Styles applied to the root element if `error={true}`. */
  error: {},
  /* Styles applied to the `NotchedOutline` element. */
  notchedOutline: {},
});

export default styles;
