import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid } from '@material-ui/core';
import {Slide, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';


import TechItem from '../../../../../../components/Technologies/TechItem/TechItem.jsx';
import mobile from '../../../../../../assets/Technologies/mobile.png';


class SectionC extends Component{

    state = {
      title:[false,false, false],
      techs:[false,false,false,false,false,false,false,false]
    }

    shouldComponentUpdate(nextProps, nextState){
      return this.props.content !== nextProps.content ||
              JSON.stringify(this.state) !== JSON.stringify(nextState)
    }


    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    }

    render(){

        const {classes, content} = this.props;
        const {title, techs} = this.state;

        return(
            
            <div  className={classes.section}>
                
              <div className={classes.container}>
              
                <Grid container align='center'>
                  <Grid item xs={12}>  
                    <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                      <Slide direction='left' in={title[0]} timeout={1000}>
                        <Typography variant='h4' align='center' className={classes.title}>
                            {content.title}
                        </Typography>
                      </Slide>
                    </InViewMonitor>
                  </Grid>
                  <Grid item xs={12}>
                    <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                      <Slide direction='right' in={title[1]} timeout={1000}>
                          <div className={classes.divider}/>
                      </Slide>
                    </InViewMonitor>
                  </Grid>
                  <Grid item xs={12}>
                  <InViewMonitor onInView={(event) => this.onInview(event, 'title', 2)} >
                    <Grow in={title[2]} timeout={1000}>
                      <div className={classes.content_container}>
                        <Typography align='center' className={classes.content}>
                          {content.content}
                        </Typography>
                      </div>
                    </Grow>
                  </InViewMonitor>
                  </Grid>
                  <Grid item xs={12}>
                    <InViewMonitor onInView={(event) => this.onInview(event, 'techs', 0)} >
                      <div className={classes.subcontainer}>
                        <Grow in={techs[0]} timeout={1000}>
                            <div className={classes.android}>
                                <TechItem data={content.data[0]}/>
                            </div>
                        </Grow>
                        <Grow in={techs[0]} timeout={1000}>
                            <div className={classes.ios}>
                                <TechItem data={content.data[1]}/>
                            </div>
                        </Grow>
                        <Grow in={techs[0]} timeout={1000}>
                            <div className={classes.ionic}>
                                <TechItem data={content.data[2]}/>
                            </div>
                        </Grow>
                        <Grow in={techs[0]} timeout={1000}>
                            <div className={classes.react}>
                                <TechItem data={content.data[3]} right={true}/>
                            </div>
                        </Grow>
                        <Grow in={techs[0]} timeout={1000}>
                            <div className={classes.xamarin}>
                                <TechItem data={content.data[4]} right={true}/>
                            </div>
                        </Grow>
                        <Grow in={techs[0]} timeout={1000}>
                            <img src={mobile} alt={''} className={classes.image}/>
                        </Grow>
                      </div>
                    </InViewMonitor>
                  </Grid>
                </Grid>
              </div>
            </div>
            
        )
    }
}

export default withStyles(styles)(SectionC);