//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        margin:'100px 0px',
    },
    container:{
        boxSizing:'border-box',
        width:'100%',
        margin:'auto',
        padding:'8px 16px',
    },
    serviceContainer:{
        margin:'40px 0'
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px'
    },
    content_container:{
        maxWidth:'550px',
        margin:'24px'
    },
    about_content:{
        color:grey[700],
        fontWeight:'400',
        fontSize:'1.1em'
    },
    image:{
        width:'100%',
    },
    imageContainer:{
        margin:'40px 250px'
    },
    box:{
        background:'red',
        height:'30px'
    },
    
    
})

export default styles;