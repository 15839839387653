//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        margin:'0px 0px 100px 0px',
    },
    container:{
        boxSizing:'border-box',
        width:'1200px',
        margin:'auto',
        padding:'16px',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'100%',
        },
    },
    titleH:{
        fontWeight:'900',
    },
    contentH:{
        color:grey[500],
        fontWeight:'600',
        padding: `0px 200px`,
    },
    title:{
        fontWeight:'900',
        fontSize:'1.4em',
        textTransform:'uppercase'
    },
    card_container:{
        padding:theme.spacing.unit*2.5,
        transform:'scale(1)',
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.complex,
        }),
        '&:hover':{
            transform:'scale(1.05)',
        }
    },
    content:{
        color:grey[500],
        fontWeight:'600',
        fontSize:'1.1em',
        padding:'0px 40px'
    },
    image:{
        margin:'auto',
    },
    icon:{
        color:theme.palette.secondary.main,
        fontSize:'2.3em',
        padding:theme.spacing.unit*4,
        borderRadius:'50%',
        background:grey[200],
        
    },
    iconB:{
        background:theme.palette.secondary.dark,
        color:'white',
    },
    button:{
        padding:'16px 48px',
        background:theme.palette.primary.main,
        color:'white',
        fontSize:'16px',
        fontWeight:'600',
        textTransform:'none',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    subcontainer:{
        padding:'0px 40px'
    },
    titleA:{
        transform:'rotate(-90deg)',
        transformOrigin: '26% 150%',
        fontSize:'6em',
        fontWeight:'600',
        color:grey[300],
    },
    company_title:{
        fontSize:'1.6em',
        fontWeight:'700',
        color:grey[900]
    },
    company_content:{
        fontSize:'1em',
        fontWeight:'500',
        color:grey[700]
    },
    mission_title:{
        fontSize:'1.4em',
        fontWeight:'700',
        color:grey[900]
    },
    mission_content:{
        fontSize:'1em',
        fontWeight:'500',
        color:grey[700]
    }
    
})

export default styles;