import React, {Component} from 'react';
import {
  Grid,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {UnderlineButton} from './customComponents.jsx';
import LanguageButton from '../../UI/Buttons/LanguageButton'
import logo from '../../../assets/logoWhite.png';
import logoColor from '../../../assets/logoXandbox.png';

import cx from 'classnames';
import styles from './styles.jsx';


class HeaderDesktop extends Component{

  state = {
    selectedTab:0,
  }

  shouldComponentUpdate(nextProps, nextState){
    return true
  }

  onChangeTab = (event, id) => {
    this.setState({selectedTab:id})
  }


  render(){

    const {classes, mode, onChange, sections, urls, history, language, onChangeLanguage} = this.props;

    const logoData = mode ? logoColor : logo;
  

    return(
      <div >
        <AppBar position='fixed' className={cx({[classes.appbar]:!mode, [classes.appbar2]:mode})}>
          <Toolbar className={classes.toolbar}>
            <Grid container alignItems='center' justify='center'>
              <Grid item>
                <div  className={
                  cx({[classes.toolbarSpanish]:language ==='spanish', 
                  [classes.toolbarEnglish]:language ==='english'})
                  }>
                  <Grid container spacing={2} alignItems='center'>
                    {sections.map((item, key) => {
                      if(key <= sections.length/2 -1){
                        return(
                          <Grid item key={key.toString()}>
                            <div className={classes.sections}>
                              <UnderlineButton
                                mode={mode}
                                id={key}
                                text={item}
                                selected={urls[key] === history.location.pathname}
                                onClick={() => onChange(urls[key])}
                              />
                            </div>
                            
                          </Grid>
                        )
                      } return null;

                    })}
                    <Grid item > 
                      <div>
                        <img src={logoData}  alt={''} className={cx({[classes.imgContainer]:!mode, [classes.imgContainer2]:mode})}/>
                      </div>
                    </Grid>
                    {sections.map((item, key) => {
                      if(key > sections.length/2 -1){
                        return(
                          <Grid item key={key.toString()}>
                            <div className={classes.sections}>
                              <UnderlineButton
                                  mode={mode}
                                  id={key}
                                  text={item}
                                  selected={urls[key] === history.location.pathname}
                                  onClick={() => onChange(urls[key])}
                                />
                            </div>
                                

                          </Grid>
                        )
                      } return null;

                    })}
                    <Grid item>
                      <LanguageButton language={language} onChange={onChangeLanguage}/>
                    </Grid>
                  </Grid>
                </div>
                
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withStyles(styles)(HeaderDesktop);
