import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Grow,  Button } from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';


class SectionI extends Component{

    state = {
        list2show:[false,false,false,false]
    }

    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    }

    render(){

        const {list2show} = this.state;

        const {classes, content, sendToPage} = this.props;

        return(
            <div className={classes.section}>
                <div className={classes.container}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={(event) => this.onInview(event, 'list2show', 0)} >
                                <Grow in={list2show[0]} timeout={1000}>
                                    <Typography align='center' className={classes.subtitle}>
                                        {content.subtitle}
                                    </Typography>
                                </Grow>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={(event) => this.onInview(event, 'list2show', 0)} >
                                <Grow in={list2show[0]} timeout={1000}>
                                    <Typography align='center' className={classes.title}>
                                        {content.title}
                                    </Typography>
                                </Grow>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}/>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={(event) => this.onInview(event, 'list2show', 0)} >
                                    <Grow in={list2show[0]} timeout={1000}>
                                        <Grid container direction='column' alignItems='center'>
                                            <Grid item>
                                                <Button variant='contained' className={classes.button} onClick={(event)=>sendToPage(event,content.url)}>
                                                    {content.button}
                                                </Button>
                                            </Grid>     
                                        </Grid>
                                    </Grow>
                            </InViewMonitor>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionI);