import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import{Typography, Grid} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
  section:{

  },
  container:{

  },
  iconContainer:{
    width:'64px',
    heigh:'64px'
  },
  icon:{
      width:'100%'
  },
  iconTitle:{
      color:grey[700],
      fontSize:'1.2em',
      fontWeight:'600'
  }
})

class TechItem extends Component{

    render(){

        const {classes, data, right, ismobile} = this.props;

        let contentLeft = null;
        let contentRight = null;
        let desktopContent = null;
        let mobileContent = null;

        if(ismobile){
          mobileContent = (
            <Grid container direction='column' alignItems='center' spacing={1}>
              <Grid item>
                <div className={classes.iconContainer}>
                  <img src={data.icon} alt={''} className={classes.icon}/>
                </div>
              </Grid>
              <Grid item>
                <Typography className={classes.iconTitle}>
                  {data.title}
                </Typography>
              </Grid>
            </Grid>
          )
        }else{
          if(right){
            contentRight = (
              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <div className={classes.iconContainer}>
                    <img src={data.icon} alt={''} className={classes.icon}/>
                  </div>
                </Grid>
                <Grid item>
                  <Typography className={classes.iconTitle}>
                    {data.title}
                  </Typography>
                </Grid>
              </Grid>
            )
          }else{
            contentLeft = (
              <Grid container alignItems='center' spacing={1}>
                <Grid item>
                  <Typography className={classes.iconTitle}>
                    {data.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <div className={classes.iconContainer}>
                    <img src={data.icon} alt={''} className={classes.icon}/>
                  </div>
                </Grid>
              </Grid>
            )
          }
          desktopContent = (
            <div>
              {contentRight}
              {contentLeft}
            </div>
          )
        }


        


        return(
          <div className={classes.container}>
            {desktopContent}
            {mobileContent}
          </div>
        )
    }
}

export default withStyles(styles)(TechItem);