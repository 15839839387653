import hours from '../../../assets/Services/features/24hours.png';
import clock from '../../../assets/Services/features/clock.png';
import innovative from '../../../assets/Services/features/innovative.png';
import money from '../../../assets/Services/features/money.png';
import powerful from '../../../assets/Services/features/powerful.png';
import usability from '../../../assets/Services/features/usability.png';

import analysis from '../../../assets/Services/process/analysis.png';
import design from '../../../assets/Services/process/design.png';
import launch from '../../../assets/Services/process/launch.png';
import maintainance from '../../../assets/Services/process/maintainance.png';
import planning from '../../../assets/Services/process/planning.png';
import programming from '../../../assets/Services/process/programming.png';
import testing from '../../../assets/Services/process/testing.png';

import service_ai from '../../../assets/Services/services/ai.png';
import service_bigdata from '../../../assets/Services/services/bigdata.png';
import service_cloudcomputing from '../../../assets/Services/services/cloudcomputing.png';
import service_cloudservices from '../../../assets/Services/services/cloudservices.png';
import service_consultancy from '../../../assets/Services/services/consultancy.png';
import service_crm from '../../../assets/Services/services/crm.png';
import service_iot from '../../../assets/Services/services/iot.png';
import service_vr from '../../../assets/Services/services/vr.png';
import service_web from '../../../assets/Services/services/web.png';
import service_mobile from '../../../assets/Services/services/mobile.png';
import service_ecommerce from '../../../assets/Services/services/ecommerce.png';





export const contentData = {
  spanish:{
    sectionA:{
      name:'Servicios',
      subnameA:'xand',
      subnameB:'box',
      phrase:'Ponemos los mejores servicios para tu negocio'
    },
    sectionB:{
      title:'Damos solución a tus necesidades',
      content:'Te ofrecemos los siguientes servicios',
      button:'Get a quote!',
      data:[
        {icon:service_web, title:'Páginas web', content:'Creamos sitios web que impulsan tu negocio a partir de las últimas tecnologías de desarrollo web y análisis de datos.'},
        {icon:service_mobile, title:'Aplicaciones móviles', content:'Desarrollamos aplicaciones móviles con tecnologías nativas e híbridas, cubriendo las plataformas iOS y Android.'},
        {icon:service_ecommerce, title:'Comercio electrónico', content:'Crece y potencializa tus ventas a partir de una tienda en línea y toma decisiones estratégicas a partir de gráficas y estadísticas.'},
        {icon:service_cloudcomputing, title:'Migración a la nube', content:'Te ayudamos a actualizar tu infraestructura asesorándote en cada paso de esta nueva transformación tecnológica. Mantén tus datos en la nube de una manera mucho más confiable y segura.'},
        {icon:service_crm, title:'Sistemas corporativos', content:'Creamos CRMs y ERPs a tu medida para agilizar la gestión de tu empresa y tus clientes. Además, diseñamos software personalizado que se adapta a tus necesidades de negocio.'},
        {icon:service_cloudservices, title:'Servicios en la nube', content:'Almacena toda tu información y procesos en la nube con los estándares más altos en seguridad, y despreocúpate por el viejo almacenamiento físico.'},
        {icon:service_ai, title:'Inteligencia artificial', content:'Creamos servicios y aplicaciones capaces de dar pronósticos, detectar patrones y crear chatbots que mejorarán tus negocios.'},
        {icon:service_iot, title:'Internet de las cosas', content:'Te ayudamos a implementar interfaces y dashboards que te permitan administrar toda tu red de dispositivos conectados a internet.'},
        {icon:service_bigdata, title:'Big data', content:'La gran cantidad de datos que hoy en día se manejan permiten dar un análisis preciso respecto a diferentes procesos que tengas en tu empresa. Te ayudamos a crear estas soluciones que acelerarán tu negocio.'},
        {icon:service_vr, title:'Realidad aumentada', content:'Creamos nuevas soluciones y aplicaciones más interactivas que están transformando la manera de atraer clientes y de llevar a cabo diferentes procesos.'},
        {icon:service_consultancy, title:'Consultoría', content:'Te asesoramos en el manejo de las nuevas tecnologías y resolvemos todas las dudas que tengas respecto a su implementación. Además, capacitamos a tu gente para que tengas un pilar fuerte de tecnología en tu empresa.'},

      ]
    },
    sectionC:{
      url:'/contact',
      subtitle:'¿Estás listo?',
      title:"¡Creemos algo grandioso juntos!",
      content:'Permítenos trabajar contigo y ayudarte a brindar las mejores herramientas tecnológicas para tu negocio.',
      button:'Contáctanos',
      data:[
        {color:'#000c3b', icon:'build', title:'Customer Support', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#003366', icon:'slideshow', title:'Sliders', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#3f5c94', icon:'star_border', title:'Winner', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#7089c5', icon:'edit', title:'Customizable', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'}
      ],
      
    },
    sectionC1:{
      title:'Creamos aplicaciones web y móviles que amarás',
      content:[
          'Ofrecemos a nuestros clientes soluciones de software a la medida que se adaptan a sus necesidades y les permiten agregar valor e impulsar sus negocios.'
      ],
      data:[
          {icon:'add', title:'Años de experiencia', content:'5'},
          {icon:'add', title:'Proyectos completados', content:'100'},
          {icon:'add', title:'Clientes', content:'24'},
          {icon:'', title:'Integrantes del equipo', content:'10'},
      ] 
    },
    sectionD:{
      title:'¿Por qué elegirnos?',
      content:'Nuestros clientes nos han elegido por diferentes razones, entre las que destacan la confiabilidad, innovación, uso de lo último en tecnología y un constante seguimiento y mantenimiento. Te presentamos otros puntos importantes a resaltar: ',
      features:[
        {icon:clock, title:'Resultados rápidos', content:'Trabajamos de manera rápida y efectiva para dar los mejores resultados.'},
        {icon:powerful, title:'Aplicaciones robustas', content:'Nuestro equipo ofrece una gran variedad de aplicaciones robustas y sostenibles.'},
        {icon:money, title:'Ahorro de dinero', content:'Nuestros productos te permiten desarrollar ahorrando dinero.'},
        {icon:hours, title:'Soporte de calidad', content:'Ofrecemos soporte de calidad en cualquier punto del mundo.'},
        {icon:innovative, title:'Tecnologías innovadoras', content:'Nuestros desarrolladores utilizan las últimas tecnologías para entregar las mejores apps.'},
        {icon:usability, title:'Gran usabilidad', content:'Mejoramos la usabilidad y experiencia de usuario como un distintivo de nuestros servicios.'},
      ]
    },
    sectionE:{
      title:"Cuidamos cada parte del proceso de tu proyecto",
      content:'Proceso',
      data:[
        {number:1,title:'Planeación', icon:planning,content:'Se identifica el problema que el cliente quiere resolver y se levantan los requerimientos del proyecto para dar una proyección de recursos, costos, tiempo y beneficios.'},
        {number:2,title:'Análisis', icon:analysis,content:'Se realiza una investigación de sistemas similares ya existentes en el mercado para ofrecer valores agregados o diferenciadores. Además, se hace una primera lluvia de ideas interna y externa.'},
        {number:3,title:'Diseño', icon:design,content:'En esta etapa primero se diseñan bosquejos a mano (whiteboarding) con el cliente, se establece el plan de trabajo, se crean los planos del proyecto (blueprints) y finalmente se crea el flujo de la aplicación final junto con diseño (wireframing).'},
        {number:4,title:'Desarrollo', icon:programming,content:'Con los diseños generados en el wireframing se empieza a programar la aplicación a nivel base de datos, backend y frontend. '},
        {number:5,title:'Pruebas', icon:testing,content:'Se realizan las primeras pruebas internas y se verifica su funcionamiento con el cliente. Si es necesario se aplican las modificaciones necesarias para satisfacer las necesidades del cliente.'},
        {number:6,title:'Lanzamiento', icon:launch,content:'Se lanza el proyecto a producción y se le entrega al cliente toda la documentación necesaria para que tenga un respaldo de la infraestructura del proyecto.'},
        {number:7,title:'Mantenimiento', icon:maintainance,content:'Brindamos el servicio de dar seguimiento a tus plataformas y, si lo deseas, damos mantenimiento preventivo y gestionamos la administración.'},
      ]
    },
    sectionF:{
      content:'Porto is everything you need to create an awesome website!',
      subcontent:'The best HTML template for your new website.',
      button:'Get Started Now'
    }
  },
  english:{
    sectionA:{
      name:'Services',
      subnameA:'xand',
      subnameB:'box',
      phrase:'We provide the best services for your business'
    },
    sectionB:{
      title:'We present solutions to your needs',
      content:'Te ofrecemos los siguientes servicios',
      button:'Get a quote!',
      data:[
        {icon:service_web, title:'Websites', content:'We create websites that drive your business using the latest web development technologies and data analysis.'},
        {icon:service_mobile, title:'Mobile apps', content:'We develop mobile applications with native and hybrid technologies, covering the iOS and Android platforms.'},
        {icon:service_ecommerce, title:'E-commerce', content:'Grow and maximize sales from your online store and use the built in analytics to make strategic decisions.'},
        {icon:service_cloudcomputing, title:'Migration to the cloud', content:'We help you update your infrastructure, advising you in each step of this new technological transformation. Keep your data in the cloud in a much more reliable and secure way.'},
        {icon:service_crm, title:'Corporate systems', content:'We create CRMs and ERPs to help you streamline the management of your company and your customers. In addition, we design customized software that adapts to your business needs.'},
        {icon:service_cloudservices, title:'Services in the cloud', content:'Store all your information and processes in the cloud with the highest security standards and safeguards.'},
        {icon:service_ai, title:'Artificial intelligence', content:'We create services and applications capable of forecasting, detecting patterns and utilizing chatbots that will help improve your business.'},
        {icon:service_iot, title:'Internet of things', content:'We help you implement interfaces and dashboards that allow you to manage your entire network of devices connected to the internet.'},
        {icon:service_bigdata, title:'Big data', content:'The large amounts of data generated today can provide an accurate overview regarding your various business processes. We help you create solutions that will analyze this data to help you accelerate your business.'},
        {icon:service_vr, title:'Augmented reality', content:'We create new solutions and interactive applications that are transforming the way to attract customers and carry out your day to day activities.'},
        {icon:service_consultancy, title:'Consultancy', content:'We advise you about new technologies, and alleviate any concerns that you have regarding its implementation. In addition, we train your people so that you have a strong technological presence in your company.'},

      ]
    },
    sectionC:{
      url:'/contact',
      subtitle:'Are you ready?',
      title:"We believe in doing something great together!",
      content:'Let us work with you to provide the best technological tools for your business.',
      button:'Contact us',
      data:[
        {color:'#000c3b', icon:'build', title:'Customer Support', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#003366', icon:'slideshow', title:'Sliders', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#3f5c94', icon:'star_border', title:'Winner', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'},
        {color:'#7089c5', icon:'edit', title:'Customizable', content:'Lorem ipsum dolor sit amet, consectetur adipiscing metus elit. Quisque rutrum pellentesque imperdiet. Quisque rutrum pellentesque imperdiet.'}
      ],
      
    },
    sectionC1:{
      title:'We create web and mobile applications that you will love',
      content:[
          'Ofrecemos a nuestros clientes soluciones de software a la medida que se adaptan a sus necesidades y les permiten agregar valor e impulsar sus negocios.'
      ],
      data:[
          {icon:'add', title:'Years of experience', content:'5'},
          {icon:'add', title:'Completed projects', content:'100'},
          {icon:'add', title:'Customers', content:'24'},
          {icon:'', title:'Team members', content:'10'},
      ] 
    },
    sectionD:{
      title:'Why choose us?',
      content:'Our clients have chosen us for our reliability, innovation, use of the latest technology and constant monitoring and maintenance. Other considerations: ',
      features:[
        {icon:clock, title:'Quick results', content:'We work quickly and effectively to give the best results.'},
        {icon:powerful, title:'Robust applications', content:'Our team offers a wide variety of robust and sustainable applications.'},
        {icon:money, title:'Budgeting', content:'Our products allow you to stay within your budget.'},
        {icon:hours, title:'Quality support', content:'We offer quality support anywhere in the world.'},
        {icon:innovative, title:'Innovative technologies', content:'Our developers use the latest technologies to deliver the best apps.'},
        {icon:usability, title:'Usability', content:'A hallmark of all our services is ease of use and an outstanding user experience.'},
      ]
    },
    sectionE:{
      title:"We provide support in every step of your project",
      content:'Process',
      data:[
        {number:1,title:'Planning', icon:planning,content:'The problem that the client wants to solve is identified and the requirements of the project are raised to give a projection of resources, costs, time and benefits.'},
        {number:2,title:'Analysis', icon:analysis,content:'An investigation is made of similar systems already existing in the market to offer aggregate or differentiating values. In addition, a first internal and external brainstorm is made.'},
        {number:3,title:'Design', icon:design,content:'At this stage, first sketches are drawn by hand (whiteboarding) with the client, the work plan is established, blueprints are created and finally the flow of the final application is created together with design (wireframing).'},
        {number:4,title:'Developing', icon:programming,content:'With the designs generated in the wireframing, the application starts to be programmed at the database level, back-end and front-end.'},
        {number:5,title:'Tests', icon:testing,content:'The first internal tests are carried out and its operation with the client is verified. If necessary, modifications are applied to satisfy the needs of the client.'},
        {number:6,title:'Launching', icon:launch,content:'The project is released to production and the client is given all the necessary documentation to have a backup of the project´s infrastructure.'},
        {number:7,title:'Maintenance', icon:maintainance,content:'We provide the service of tracking your platforms and, if you wish, we provide preventive maintenance and management.'},
      ]
    },
    sectionF:{
      content:'Porto is everything you need to create an awesome website!',
      subcontent:'The best HTML template for your new website.',
      button:'Get Started Now'
    }
  }
}