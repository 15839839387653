import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Grid,
  Typography,
  OutlinedInput,
  withWidth,
  InputAdornment,
  Icon
} from '@material-ui/core';
import {blueGrey} from '@material-ui/core/colors';


class InputText extends Component{

  render(){

    const {
      classes,
      placeholder,
      value,
      onChange,
      disabled,
      error,
      helper,
      multiline,
      type,
      fullWidth,
      icon,
      rows
    } = this.props;

    let inputaddornment = null;
    if(icon !== 'undefined' || icon !== 'null'){
      inputaddornment = 
      <InputAdornment position='start'>
        <Icon style={{color:blueGrey[200], paddingLeft:'8px'}}>
          {icon}
        </Icon>
      </InputAdornment>
    }

    return(
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction='column'>
            <Grid item>
                <OutlinedInput
                  margin="none"
                  variant="outlined"
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  disabled={disabled}
                  error={error}
                  value={value}
                  onChange={onChange}
                  multiline={multiline}
                  rows={rows}
                  type={type}
                  startAdornment={inputaddornment}
                  labelWidth={0}
                  inputProps={{
                    spellCheck:"false"
                  }}
                  classes={classes}
                />
            </Grid>
            <Grid item>
              <Typography variant='caption' className={classes.helper}>
                {error ? helper : ''}
              </Typography>
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    );
  }
};

export default withStyles(styles)(withWidth()(InputText));
