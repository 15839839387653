import { Grid, Slide, Typography, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import styles from './styles'
import InViewMonitor from 'react-inview-monitor';
import Carousel from '../Carousel/Carousel';
import cx from 'classnames'

const CarouselSection = (props) => {


    const [titleEvent, setTitle] = useState(false)
    const [dividerEvent, setDivider] = useState(false)
    const {classes, title, grey, noBottomPadding} = props

    return(
        <div className={cx({
            [classes.greyBG]:grey,
            [classes.noBottomPadding]:noBottomPadding
        })}>
            <div 
                className={cx({
                    [classes.section]:true,
                    [classes.noBottomPadding]:noBottomPadding
                })}
            >
                <Grid container direction='column' align='center' spacing={2}>
                    <Grid item xs>  
                        <InViewMonitor onInView={(event) => setTitle(true)} >
                            <Slide direction='left' in={titleEvent} timeout={1000}>
                                <Typography variant='h4' align='center' className={classes.title}>
                                    {title}
                                </Typography>
                            </Slide>
                        </InViewMonitor>
                    </Grid>
                    <Grid item xs>
                        <InViewMonitor onInView={(event) => setDivider(true)} >
                            <Slide direction='right' in={dividerEvent} timeout={1000}>
                                <div className={classes.divider}/>
                            </Slide>
                        </InViewMonitor>
                    </Grid>
                    <Grid item xs>
                        <Carousel {...props}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default withStyles(styles)(CarouselSection)