//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';
//
import background from '../../../../../../assets/Projects/project.jpg';


const styles = (theme) => ({
    root:{
        position:'relative',
        overflow:'hidden',
        height: '100vh',
    },
    background:{
        position:'absolute',
        backgroundImage: `linear-gradient(135deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url(${background})`,
        width:'100%',
        height: '100%',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        zIndex:-1,
    },
    textContainer:{
        width:'100%',
        position:'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color:'white',
        zIndex:10,
        height:'100vh'
    },
    textTitle:{
        letterSpacing:theme.spacing.unit*2,
        color:'white',
        fontWeight:'700'    
    },
    textContent:{
        width:'600px',
        margin:'auto',
        letterSpacing:theme.spacing.unit*0.6,
        color:'white',
        fontWeight:'600'
    },
    
})

export default styles;