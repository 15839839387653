import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import{Typography, Grid, Grow, Fade } from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import cx from 'classnames';

const styles = (theme) => ({
  section:{

  },
  container:{
    position:'relative'
  },
  subcontainer:{
    padding:'18px 0px',
    width:'380px',
    [theme.breakpoints.down('sm')]: {
      boxSizing:'border-box',
      paddingLeft:'88px',
      width:'100%',
    },
  },
  subcontainer2:{
    padding:'18px 0px',
    width:'380px'
  },
  titleA:{
    color:grey[500],
    fontSize:'1.5em',
    fontWeight:'600'
  },
  titleB:{
    color:grey[900],
    fontSize:'1.5em',
    fontWeight:'600'
  },
  content:{
    color:grey[700],
    fontSize:'1em',
    fontWeight:'400'
  },
  logoContainer:{
    position:'absolute',
    width:'100%',
  },
  logoSubcontainer:{
    margin:'auto',
    width:'28px',
    height:'28px',
    padding:theme.spacing.unit*2,
    borderRadius:'50%',
    backgroundColor:'#4b6bf5',
    border:'4px solid white',
    boxShadow:'0 6px 12px 0 rgba(82, 91, 115, .2)',
    [theme.breakpoints.down('sm')]: {
      margin:0,
    },
  },
  image:{
      width:'100%'
  },  
  colorA:{
    background:'linear-gradient(135deg, #ff4dae, #4268ff)'
  },
  colorB:{
      background:'linear-gradient(135deg, #ff9f2e, #f723c4)'
  },
  colorC:{
      background:'linear-gradient(135deg, #00f2aa, #0083ff)'
  },
  colorD:{
      background:'linear-gradient(135deg, #ffe10a, #ff3e5c)'
  },
  
})

class StepProcess extends Component{

    render(){

        const {classes, data, position, color, visible, ismobile} = this.props;

        let leftContent = null;
        let rightContent = null;
        let mobileContent = null;
        let desktopContent = null;

        if(ismobile){
          mobileContent = (
            <Fade in={visible} timeout={2000}>
                <div className={classes.subcontainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={1} >
                        <Grid item>
                          <Typography className={classes.titleA}>
                            {'Step '+data.number.toString()+'.'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.titleB}>
                            {data.title}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.content}>
                        {data.content}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
          )
        }else{
          if(position === 'left'){
            leftContent = (
              <Fade in={visible} timeout={2000}>
                <div className={classes.subcontainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={1} justify='flex-end'>
                        <Grid item>
                          <Typography align='right' className={classes.titleA}>
                            {'Step '+data.number.toString()+'.'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.titleB}>
                            {data.title}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align='right' className={classes.content}>
                        {data.content}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
              
            )
          }else{
            rightContent = (
              <Fade in={visible} timeout={2000}>
                <div className={classes.subcontainer2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={1} justify='flex-start'>
                        <Grid item>
                          <Typography align='left' className={classes.titleA}>
                            {'Step '+data.number.toString()+'.'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.titleB}>
                            {data.title}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align='left' className={classes.content}>
                        {data.content}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
              
            )
          }
          desktopContent = (
            <Grid container>
              <Grid item xs={6}>
                  {leftContent}
              </Grid>
              <Grid item xs={6}>
                  <Grid container>
                    <Grid item style={{flex:1}}/>
                    <Grid item>
                      {rightContent}
                    </Grid>
                  </Grid>
                  
              </Grid>
            </Grid>
          )
        }

        

        return(
          <div className={classes.container}>
            <Grow in={visible} timeout={1000}>
              <div className={classes.logoContainer}>
                <div 
                    className={cx({
                        [classes.logoSubcontainer]:true,
                        [classes.colorA]:color === 0,
                        [classes.colorB]:color === 1,
                        [classes.colorC]:color === 2,
                        [classes.colorD]:color === 3
                    })}
                >
                    <img src={data.icon} alt='' className={classes.image}/>
                </div>
              </div>
            </Grow>
            
            {desktopContent}
            {mobileContent}
          </div>
        )
    }
}

export default withStyles(styles)(StepProcess);