//import { fade } from '@material-ui/core/styles/colorManipulator';
import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
    section:{
        padding:'100px 0px',
        background:grey[50]
    },
    container:{
        boxSizing:'border-box',
        width:'1200px',
        margin:'auto',
        padding:'16px',
        [theme.breakpoints.up('lg')]: {
            width:'1200px',
        },
        [theme.breakpoints.only('md')]: {
            width:'100%',
        },
    },
    serviceContainer:{
        margin:'40px 0'
    },
    divider:{
        marginTop:'32px',
        width:'50px',
        minHeight:'5px',
        background:grey[300]
    },
    title:{
        fontWeight:'700',
        maxWidth:'500px'
    },
    content_container:{
        maxWidth:'550px',
        margin:'24px',
        
    },
    about_content:{
        fontWeight:'400',
        fontSize:'1em',
        color:grey[700]
    },
    image:{
        width:'100%',
    },
    imageContainer:{
        margin:'40px 250px'
    },
    features:{
        padding:'80px 0'
    }
    
})

export default styles;