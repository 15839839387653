//import { fade } from '@material-ui/core/styles/colorManipulator';
//import {grey} from '@material-ui/core/colors';

const styles = (theme) => ({
  container:{
    display:'flex',
    verticalAlign:'middle',
    height:'100vh',
    overflow:'hidden'
  }, 
  logo:{
    width:'210px'
  },
  spinnerContainer:{
  },
    
})

export default styles;