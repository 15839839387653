import amazon from '../../../assets/Technologies/logos/amazon.png';
import android from '../../../assets/Technologies/logos/android.png';
import angular from '../../../assets/Technologies/logos/angular.png';
import apple from '../../../assets/Technologies/logos/apple.png';
import ionic from '../../../assets/Technologies/logos/ionic.png';
import javascript from '../../../assets/Technologies/logos/javascript.png';
import node from '../../../assets/Technologies/logos/node.png';
import react from '../../../assets/Technologies/logos/react.png';
import php from '../../../assets/Technologies/logos/php.png';
import python from '../../../assets/Technologies/logos/python.png';
import ruby from '../../../assets/Technologies/logos/ruby.png';
import xamarin from '../../../assets/Technologies/logos/xamarin.png';
import ibm from '../../../assets/Technologies/logos/ibm.png';
import google from '../../../assets/Technologies/logos/google.png';
import azure from '../../../assets/Technologies/logos/azure.png';
import aspnet from '../../../assets/Technologies/logos/aspnet.png';
import cplus from '../../../assets/Technologies/logos/cplus.png';
import csharp from '../../../assets/Technologies/logos/csharp.png';
import java from '../../../assets/Technologies/logos/java.png';
import mongo from '../../../assets/Technologies/logos/mongo.png';
import mysql from '../../../assets/Technologies/logos/mysql.png';
import oracle from '../../../assets/Technologies/logos/oracle.png';
import postgresql from '../../../assets/Technologies/logos/postgresql.png';
import sqlserver from '../../../assets/Technologies/logos/sqlserver.png';
import matlab from '../../../assets/Technologies/logos/matlab.png';
import tensorflow from '../../../assets/Technologies/logos/tensorflow.png';
import watson from '../../../assets/Technologies/logos/watson.png';


export const contentData = {
  spanish:{
    sectionA:{
      name:'Tecnologías',
      subnameA:'xand',
      subnameB:'box',
      phrase:'Nos mantenemos a la vanguardia de las últimas tecnologías'
    },
    sectionB:{
      title:'Desarrollo web',
      content:'Ofrecemos desarrollos web personalizados y adaptables a todo tipo de dispositivos que permiten a empresas líderes y negocios emergentes proporcionar experiencias flexibles y consistentes a los usuarios.',
      data:[
        {title:'Javascript', icon:javascript},
        {title:'React', icon:react},
        {title:'Angular', icon:angular},
        {title:'PHP', icon:php},
        {title:'Python', icon:python},
        {title:'Node', icon:node},
        {title:'Ruby', icon:ruby},
      ]
    },
    sectionC:{
      title:'Desarrollo móvil',
      content:'Xandbox brinda a los usuarios experiencias móviles elegantes, productivas y fáciles de usar. Gracias a nuestra amplia experiencia en el desarrollo de aplicaciones multiplataforma nativas, híbridas y web, te ayudamos a seleccionar la mejor opción para tu proyecto en términos de costos, tiempo y escalabilidad.',
      data:[
        {title:'Android', icon:android},
        {title:'iOS', icon:apple},
        {title:'Ionic', icon:ionic},
        {title:'React Native', icon:react},
        {title:'Xamarin', icon:xamarin},
      ]
      
    },
    sectionD:{
      title:'Computación en la nube',
      content:'En Xandbox contamos con más de cinco años de experiencia trabajando con servicios de infraestructura de la nube y ayudando a que la infraestructura de TI y los procesos empresariales de nuestros clientes se vuelvan flexibles a través de la implementación de soluciones basadas en IaaS, PaaS y SaaS.',
      data:[
        {title:'AWS', icon:amazon},
        {title:'Azure', icon:azure},
        {title:'Google Cloud', icon:google},
        {title:'IBM', icon:ibm},
      ]
    },
    sectionE:{
      title:'Software empresarial',
      content:'Los sistemas de software permiten a las empresas operar negocios de manera eficiente, administrar el crecimiento de manera efectiva, reducir el tiempo de inactividad, disminuir costos y mejorar la experiencia del usuario.',
      data:[
        {title:'ASP NET', icon:aspnet},
        {title:'C++', icon:cplus},
        {title:'C#', icon:csharp},
        {title:'Java', icon:java},
      ]
    },
    sectionF:{
      title:'Bases de datos',
      content:'Los datos se están convirtiendo cada vez más en una parte importante de la estrategia comercial de cada negocio. El éxito de una organización depende en gran medida de cómo decidan utilizar sus datos comerciales para obtener una ventaja competitiva.',
      data:[
        {title:'SQL Server', icon:sqlserver},
        {title:'MySQL', icon:mysql},
        {title:'PostgreSQL', icon:postgresql},
        {title:'Mongo DB', icon:mongo},
        {title:'Oracle', icon:oracle},
      ]
    },
    sectionG:{
      title:'Inteligencia artificial',
      content:'Con nuestra amplia experiencia en el desarrollo de software de inteligencia artificial, ayudamos a nuestros clientes a descubrir nuevos valores de negocio a través del aprendizaje automático y la computación cognitiva.',
      data:[
        {title:'MATLAB', icon:matlab},
        {title:'Python', icon:python},
        {title:'TensorFlow', icon:tensorflow},
        {title:'IBM Watson', icon:watson},
      ]
    }
  },
  english:{
    sectionA:{
      name:'Technologies',
      subnameA:'xand',
      subnameB:'box',
      phrase:'We remain at the forefront of the latest technologies'
    },
    sectionB:{
      title:'Web development',
      content:'We offer customized web solutions, adaptable to all devices which allow leading companies and emerging businesses to provide flexible and consistent experiences to users.',
      data:[
        {title:'Javascript', icon:javascript},
        {title:'React', icon:react},
        {title:'Angular', icon:angular},
        {title:'PHP', icon:php},
        {title:'Python', icon:python},
        {title:'Node', icon:node},
        {title:'Ruby', icon:ruby},
      ]
    },
    sectionC:{
      title:'Mobile development',
      content:'Xandbox provides users with elegant, productive and easy-to-use mobile experiences. Thanks to our extensive experience in the development of native, hybrid and multiplatform applications, we help you select the best option for your project in terms of costs, time and scalability.',
      data:[
        {title:'Android', icon:android},
        {title:'iOS', icon:apple},
        {title:'Ionic', icon:ionic},
        {title:'React Native', icon:react},
        {title:'Xamarin', icon:xamarin},
      ]
      
    },
    sectionD:{
      title:'Cloud computing',
      content:'At Xandbox we have more than five years of experience working with cloud infrastructure services and helping the IT infrastructure and business processes of our clients to become flexible through the implementation of solutions based on IaaS, PaaS and SaaS.',
      data:[
        {title:'AWS', icon:amazon},
        {title:'Azure', icon:azure},
        {title:'Google Cloud', icon:google},
        {title:'IBM', icon:ibm},
      ]
    },
    sectionE:{
      title:'Business software',
      content:'Our software systems allow companies to operate businesses efficiently, manage growth effectively, reduce downtime, reduce costs and improve the user experience.',
      data:[
        {title:'ASP NET', icon:aspnet},
        {title:'C++', icon:cplus},
        {title:'C#', icon:csharp},
        {title:'Java', icon:java},
      ]
    },
    sectionF:{
      title:'Databases',
      content:'Data is increasingly becoming an important part of the business strategy of businesses worldwide. The success of an organization depends to a large extent on how they decide to use their business data to obtain a competitive advantage.',
      data:[
        {title:'SQL Server', icon:sqlserver},
        {title:'MySQL', icon:mysql},
        {title:'PostgreSQL', icon:postgresql},
        {title:'Mongo DB', icon:mongo},
        {title:'Oracle', icon:oracle},
      ]
    },
    sectionG:{
      title:'Artificial intelligence',
      content:'With our extensive experience in the development of artificial intelligence software, we help our clients discover new business values through machine learning and cognitive computing.',
      data:[
        {title:'MATLAB', icon:matlab},
        {title:'Python', icon:python},
        {title:'TensorFlow', icon:tensorflow},
        {title:'IBM Watson', icon:watson},
      ]
    }
  }
}