import Home from '../views/Principal/Home/Home.jsx';
import Technologies from '../views/Principal/Technologies/Technologies.jsx';
import Services from '../views/Principal/Services/Services.jsx';
import About from '../views/Principal/About/About.jsx';
import Contact from '../views/Principal/Contact/Contact.jsx';
import Projects from '../views/Principal/Projects/Projects.jsx'





const dashboardRoutes = [
    {
      path: "/home",
      component: Home
    },
    {
      path: "/technologies",
      component: Technologies
    },
    {
      path: "/services",
      component: Services
    },
    {
      path: "/projects",
      component: Projects
    },
    {
      path: "/about",
      component: About
    },
    {
      path: "/contact",
      component: Contact
    },
    { redirect: true, path: "/", to: "/home", navbarName: "Redirect" }
  ]
  
  export default dashboardRoutes;