import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid } from '@material-ui/core';
import {Slide, Grow} from '@material-ui/core';
import InViewMonitor from 'react-inview-monitor';


import ServiceCard from '../../../../../../components/Services/ServiceCard/ServiceCard.jsx';

class SectionB extends Component{

    state = {
        title:[false,false],
        services:[false,false,false,false,false,false,false,false,false,false,false],
        anchorEl:null,
        open:false
      }

    openDialog = (event) => {
        let anchorEl = event.target;
        this.setState({open:true, anchorEl:anchorEl})
    }
    onCloseMessage = (event) => {
        this.setState({open:false, anchorEl:null})
    }


    onInview = (event, key, pos) => {
        let temp = [...this.state[key]];
        temp[pos] = true;
        this.setState({[key]:[...temp]})
    }

    render(){

        const {classes, content, visited} = this.props;
        const {title, services} = this.state;
        const timein = 1500;

        

        return(
            
            <div  className={classes.section}>
                
                <div className={classes.container}>
                
                    <Grid container align='center'>
                        <Grid item xs={12}>  
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 0)} >
                                <Slide direction='left' in={title[0]} timeout={1000}>
                                    <Typography variant='h4' align='center' className={classes.title}>
                                        {content.title}
                                    </Typography>
                                </Slide>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}>
                            <InViewMonitor onInView={(event) => this.onInview(event, 'title', 1)} >
                                <Slide direction='right' in={title[1]} timeout={1000}>
                                    <div className={classes.divider}/>
                                </Slide>
                            </InViewMonitor>
                        </Grid>
                        <Grid item xs={12}>  
                            <Grow in={visited} timeout={timein+500}>
                                <div className={classes.content_container}>
                                    <Typography variant='body1' align='center' className={classes.about_content}>
                                        {content.content}
                                    </Typography>
                                </div>
                            </Grow>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.serviceContainer}>
                                <Grid container spacing={3} justify='center'>
                                    {
                                        content.data.map((item,key)=>{
                                            return(
                                                <Grid item xs={12} md={4} key={key.toString()}>
                                                    <InViewMonitor onInView={(event) => this.onInview(event, 'services', key)} >
                                                        <Grow in={services[key]} timeout={700+500*(key%3)}>
                                                            <div>
                                                                <ServiceCard 
                                                                    data={item} 
                                                                    color={key%4}
                                                                    timeout={1500+500*(key%3)}
                                                                    ismobile
                                                                />
                                                            </div>
                                                            
                                                        </Grow>
                                                        
                                                    </InViewMonitor>
                                                </Grid>
                                            )
                                        })
                                    }
                                    
                                </Grid>
                            </div>
                            
                        </Grid>
                        
                    </Grid>
                        
                </div>
            </div>
            
        )
    }
}

export default withStyles(styles)(SectionB);