import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import{Typography, Grid, Fade } from '@material-ui/core';

class SectionA extends Component{

    render(){

        const {classes,content} = this.props;

        return(
            <div className={classes.root}>

                <div className={classes.background}/>
                <div className={classes.textContainer}>
                    <div style={{width:'100%'}}>
                        <Grid container direction='column' alignItems='center' spacing={1}>
                            <Grid item>
                                <Fade in timeout={1000}>
                                    <Typography variant='h1' className={classes.textTitle}>
                                    {content.sectionA.name}
                                    </Typography>
                                </Fade>    
                            </Grid>
                            <Grid item>
                                <Fade in timeout={2000}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography variant='h3' className={classes.textSubtitleA}>
                                                {content.sectionA.subnameA}
                                            </Typography>   
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='h3' className={classes.textSubtitleB}>
                                                {content.sectionA.subnameB}
                                            </Typography>  
                                        </Grid>
                                    </Grid>
                                </Fade>  
                            </Grid>
                            <Grid item style={{height:'64px'}}/>
                            <Grid item>
                                <Fade in timeout={3000}>
                                    <Typography variant='h6' className={classes.textContent}>
                                    {content.sectionA.phrase}
                                    </Typography>
                                </Fade>            
                            </Grid>
                        </Grid>
                    </div>            
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(SectionA);