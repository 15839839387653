import { Grid, Typography, withStyles } from '@material-ui/core'
import React, {Component} from 'react'
import styles from './styles'
import cx from 'classnames'
import monitor from '../../../assets/Projects/monitor.png';
import monitor2 from '../../../assets/Projects/monitor2.png';
import laptop from '../../../assets/Projects/laptop.png';
import tablet from '../../../assets/Projects/tablet.png';
import smartphone from '../../../assets/Projects/phone.png';

const fixedImages = {
    monitor:monitor, 
    monitor2:monitor2,
    laptop:laptop, 
    tablet:tablet,
    smartphone: smartphone
}

class Carousel extends Component{

    state={
        flag:0,
        total:0,
        interval:null
    }

    componentDidMount = () => {
        const {content} = this.props
        this.setState({total:content.length})
        this.setState({interval:this.handleCarousel()})
    }

    setFlagTo = async(key) => {
        this.setState({flag: key})
        clearInterval(this.state.interval)
        this.setState({interval:this.handleCarousel()})
    }

    handleCarousel = () => {
        return setInterval(() => {
            const {flag, total} = this.state
            let newFlag = (flag+1 == total) ? 0 : flag+1
            this.setState({flag: newFlag})
        }, 5000)
    }

    render(){
        const {classes, content, fixedImage, imagePosition, mobile} = this.props
        const {flag} = this.state
        
        let image = (
            <Grid item xs={12} md={7} style={{position:'relative'}}>
                <img src={fixedImages[fixedImage]} 
                    className={cx({
                        [classes.fixedImage]:true,
                        [classes.fixedImageMobile]:fixedImage==='smartphone'
                    })}
                />
                <img src={content[flag].image} 
                    className={cx({
                        [classes.imageMonitor]:fixedImage==='monitor', 
                        [classes.imageMonitor2]:fixedImage==='monitor2',
                        [classes.imageLaptop]:fixedImage==='laptop',
                        [classes.imageTablet]:fixedImage==='tablet',
                        [classes.imagePhone]:fixedImage==='smartphone'
                    })}/> 
            </Grid>
        )

        return(
            <Grid container>
                {imagePosition === 'left' || mobile ? image : null}
                <Grid item xs={12} md={5}>
                    <div className={classes.content}>
                        {content.map((item, key) => {
                            return(
                                <div 
                                    className={cx({
                                        [classes.hidden]:true,
                                        [classes.show]:flag === key
                                    })}
                                    key={key}
                                >
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <Typography variant='h4' align='center' className={classes.title}>
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='body1' align='justify' className={classes.text}>
                                                {item.content}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        })}              
                    </div>
                    <div className={classes.content}>
                        <Grid 
                            container 
                            justify='center' 
                            alignItems='center' 
                            spacing={2}
                        >
                            {content.map((item,key) => {
                                return(
                                    <Grid item key={key}>
                                        <div className={cx({
                                                [classes.tag]:true,
                                                [classes.activeTag]:flag===key
                                            })} 
                                            onClick={() => this.setFlagTo(key)}
                                        ></div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                </Grid>
                {imagePosition === 'right' && !mobile ? image : null}
            </Grid>    
        )
    }
}

export default withStyles(styles)(Carousel)